import axios from "axios";

const API_URL = (process.env.REACT_APP_API_BASE_URL as string);

class ReferrerService {
    list(filter : string) {
        return axios.get(API_URL + "/api/Referrers?filter=" + filter)
    }

    get(id : string) {
        return axios.get(API_URL + "/api/Referrers/" + id)
    } 

    getHitStats(id : string) {
        return axios.get(API_URL + "/api/Referrers/" + id + "/HitStats")
    }

    getBalance(id : string) {        
        return axios.get(API_URL + "/api/Referrers/" + id + "/GetBalance")
    }

    createReferrer(data : any) {
        return axios.post(API_URL + "/api/Referrers", data)
    }
}

export default new ReferrerService();