import React, { Component } from "react";
import NavBar from './components/NavBar';
import Login from './pages/Login';
import Experiences from './pages/Experiences';
import Experience from './pages/Experience';
import ExperienceCreate from './pages/ExperienceCreate';
import Referrer from './pages/Referrer';
import Referrers from './pages/Referrers';
import NotFound from './pages/NotFound';
import Landing from './pages/Landing';
import RegisterAccount from './pages/RegisterAccount';
import ProtectedRoute from './components/ProtectedRoute';
import ReferrerCreate from './pages/ReferrerCreate';
import AuthService from "./services/AuthService";

import {
	createBrowserRouter,
	RouterProvider,
	Route,
	Routes,
	Link,
	BrowserRouter,
	Outlet,
  } from "react-router-dom";
import User from "./pages/User";

interface IAppProps {
}

interface IAppState { 
	currentUser: any,
	showNavBar: boolean,
}

class App extends Component<IAppProps, IAppState> {
	constructor(props: IAppProps) {
		super(props)
	
		this.state = {
			currentUser: null,
			showNavBar: false,
		}
	}
	
	getInitialState() {
		
		return { 
			currentUser: null,
			showNavBar: false, 
		};
    }

	componentDidMount() {
        document.body.classList.add(
			"bg-gray-300",
	   	);
		   
		document.title = 'Game Link';

	  
		const user = AuthService.getCurrentUser();
	
		if (user) {
		  this.setState({
			currentUser: user,
			showNavBar: user != null,
		  });
		}
	}

	render() {
		return (
			<BrowserRouter>
				<div className="bg-gray-300">				
					{this.state.showNavBar ? <NavBar /> : null }
					
					<div className={`relative ${this.state.showNavBar ? "md:ml-64" : ""}`}>
						<nav className='absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4'>
							
						</nav>
						<header> 
							<Routes>
								<Route path="/" element={<ProtectedRoute><Landing /></ProtectedRoute>} />
								<Route path="/login" element={<Login />} />
								<Route path="/referrers" element={<ProtectedRoute><Referrers /></ProtectedRoute>} />
								<Route path="/referrers/new" element={<ProtectedRoute><ReferrerCreate /></ProtectedRoute>} />
								<Route path="/referrers/:referrerId" element={<ProtectedRoute><Referrer /></ProtectedRoute>} />
								<Route path="/experiences" element={<ProtectedRoute><Experiences /></ProtectedRoute>} />
								<Route path="/experiences/:experienceId" element={<ProtectedRoute><Experience /></ProtectedRoute>} />
								<Route path="/experiences/new" element={<ProtectedRoute><ExperienceCreate /></ProtectedRoute>} />
								<Route path="/users/:userId" element={<ProtectedRoute><User /></ProtectedRoute>} />
								<Route path="/users/new" element={<RegisterAccount />} />
								<Route path="*" element={<NotFound />} />
							</Routes>
						</header>
					</div>
		
				</div>
			</BrowserRouter>
		);
	}
}

export default App;
