import React, { Component, useState, useEffect } from "react";

function Landing() {
    return (
        <div className="">
            <section className="h-full gradient-form md:h-screen text-center mt-10">
                <p className="text-3xl mb-5">404 Page not found</p>
                <a className="button" href="/">Go to start</a>
            </section>
        </div>
    )
}

export default Landing