import React, { Component, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import UserService from "../services/UserService"
import AuthService from "../services/AuthService"
import { create } from "domain";

function ManageApiKeys(props: any) {
    const [apiKeys, setApiKeys] = useState<any>([]);

    const refresh = () => {
        const userId = AuthService.getCurrentUser().userId

        UserService.listApiKeys(userId).then((response) => {
            setApiKeys(response.data);
        });
    }

    useEffect(() => {
        refresh()
    }, []);

    const deleteApiKey = (apiKey : any) => {
        const userId = AuthService.getCurrentUser().userId

        UserService.deleteApiKey(userId, apiKey.id).then((response) => {
            refresh()
        });
    }

    const createApiKey = () => {
        const userId = AuthService.getCurrentUser().userId

        UserService.createApiKey(userId).then((response) => {
            const keys = [...apiKeys] // Dereference the state
            keys.push(response.data)
            setApiKeys(keys)
        })
    }

    return (
        <div className="w-full rounded-lg bg-white p-6 mb-6">
            <p className="block uppercase text-blueGray-600 text-xs font-bold mb-2">API KEYS</p>

            {apiKeys.map(function (apiKey: any, i: number) {
                return (
                    <div key={apiKey.id}>
                        <button
                            className="inline-block px-6 py-2.5 border-red-600 border-2 text-red-600 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-100 hover:shadow-lg focus:bg-red-100 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out w-1/5 float-right"
                            type="button"
                            onClick={() => deleteApiKey(apiKey)}
                            data-mdb-ripple="true"
                            data-mdb-ripple-color="light">
                            DELETE
                        </button>

                        <input
                            type="text"
                            className="form-control mb-4 block w-3/4 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            id="gameLink"
                            name="gameLink"
                            readOnly
                            value={apiKey.value}
                        />
                    </div>
                )
            })}

            <button
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                type="submit"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
                onClick={() => createApiKey()}>
                Create API Key
            </button>

        </div>
    )
}

function User(props: any) {
    return (
        <div className="">
            <section className="h-full w-1/3 gradient-form md:h-screen">
                <div className="container py-12 px-6 h-full">
                    <div className="w-full flex items-center rounded-lg background-tgblue mb-6">
                        <div className="text-white px-2 py-6 md:px-2 md:py-12 md:mx-6">
                            <h4 className="text-xl font-semibold mb-6">Update User</h4>
                            <p className="text-sm">
                                Edit your user details and create API keys
                            </p>
                        </div>
                    </div>

                    <ManageApiKeys />
                </div>
            </section>
        </div>
    )
}

export default User