import React, { Component, useState, useEffect } from "react";
import Select from "react-select";

import ReferrerService from "../services/ReferrerService";
import ExperienceService from "../services/ExperienceService";
import GameLinkService from "../services/GameLinkService";

function Selector(props: any) {
	const change = (selectedItem : any) => {
		props.onChange(selectedItem)
	}

	props.items.map(function(item : any, i : number) {
		if (!item.label) {
			item.label = item.name
		}

		if (!item.value) {
			item.value = item.id
		}
	});


	return (<div className="mb-6">
		<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="referrerId">{props.title}</label>
		
		<Select options={props.items} onChange={change} />

	</div>)
}

function GameLinkField(props : any) {
	const [copyText, setCopyText] = useState("COPY")

	const linkURL = GameLinkService.create(props.experience.slug, props.referrer.slug)

	const copyURL = () => {
		navigator.clipboard.writeText(linkURL);

		setCopyText("COPIED!")

		setTimeout(() => {
			setCopyText("COPY")
		}, 1000)
	}

	return (<div>
		<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="gameLink">GAME LINK</label>
		
		<div className="relative">
			<input
				type="text"
				className="form-control mb-4 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
				id="gameLink"
				name="gameLink"
				readOnly
				value={linkURL}
			/>

			<button type="button" onClick={copyURL} className="text-white absolute right-2 bottom-2 text-xs bg-blue-700 hover:bg-blue-800 focus:outline-none active:bg-blue-500 font-medium rounded-lg text-sm px-3 py-1 ">{copyText}</button>
		</div>

		<p className="text-xs">Copy and paste the url above to where you want to link to the game. Players who click and play using the link will be automatically registered in this system.</p>
		
	</div>)
}

interface ICreateTrackingProps {
	referrerId?: any,
	experienceId?: any,
}

function Referrer(props : ICreateTrackingProps) {	
	const [referrers, setReferrers] = useState([]);
	const [experiences, setExperiences] = useState([]);
	const [selectedReferrer, setSelectedReferrer] = useState<any>(null);
	const [selectedExperience, setSelectedExperience] = useState<any>(null);

	useEffect(() => {  
		if (!props.referrerId) {
			ReferrerService.list("all").then((response) => {
				setReferrers(response.data);
			})
		} else {
			ReferrerService.get(props.referrerId).then((response) => {
				setSelectedReferrer(response.data);
			})
		}

		if (!props.experienceId) {
			ExperienceService.list("all").then((response) => {
				setExperiences(response.data);
			})
		} else {
			setSelectedExperience(props.experienceId)
		}
	}, []);

	return (
		<div className="w-1/2 rounded-lg bg-white p-6">
			<h4 className="font-bold mb-6">CREATE TRACKING CODE</h4>

			<form>
				<div className="relative w-full mb-3">
					{ props.referrerId ? "" : <Selector items={referrers} title="Select referrer" onChange={setSelectedReferrer}/>  }
					{ props.experienceId ? "" : <Selector items={experiences} title="Select experience" onChange={setSelectedExperience} /> }

					{ selectedExperience && selectedReferrer ? <GameLinkField experience={selectedExperience} referrer={selectedReferrer} />  : ""}

				</div>
			</form>
		</div>
	)
}

export default Referrer