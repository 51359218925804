import { Navigate } from "react-router-dom";
import AuthService from "../services/AuthService";

const ProtectedRoute = ({ children }: any) => {
    const user = AuthService.getCurrentUser();

    if (!user) {
        return <Navigate to="/login" />;
    }

    return children;
};

export default ProtectedRoute