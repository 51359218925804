import { Console } from "console";
import React, { Component, useState } from "react";
import { useForm } from "react-hook-form";

import AuthService from "../services/AuthService";

import { Link, useNavigate } from "react-router-dom";

type FormData = {
	email: string;
	password: string;
	confirmPassword: string;
};

export default function RegistrationForm(props : any) {
	const onError = (error : any) => {
		if (error.response.status == 400) {
			setServerError("Invalid email or password")
		}
		else {
			setServerError("Unknown error occured")
		}
	}

	const authenticate = (data : FormData) => {
		AuthService.register(data.email, data.password).then(() => {
			AuthService.login(data.email, data.password).then(() => {
				window.location.href = "/";
			});
		}).catch(onError)
	};

	const { register, setValue, handleSubmit, formState: { errors }, watch } = useForm<FormData>();
	const onSubmit = handleSubmit(authenticate);
	const [serverError, setServerError] = useState("");

	const resetServerError = (e : any) => {
		setServerError("")
		setValue(e.target.name, e.target.value);
	}

	return (
		<form onSubmit={onSubmit}>
			<p className="mb-4">Register new account</p>
			<div className="">
				<input
					{...register("email", { required: true })}
					type="text"
					className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
					id="exampleFormControlInput1"
					placeholder="Email"
					onChange={resetServerError}
				/>
			</div>

			{errors.email?.type === 'required' && <p role="alert" className="text-red-500">Email is required</p>}

			<div className="mt-4">
				<input
					{...register("password", { required: true })}
					type="password"
					className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
					id="exampleFormControlInput1"
					placeholder="Password"
					onChange={resetServerError}
				/>
			</div>

			{errors.password?.type === 'required' && <p role="alert" className="text-red-500">Password is required</p>}

			<div className="mt-4">
				<input
					{...register("confirmPassword", { required: true })}
					type="password"
					className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
					id="exampleFormControlInput1"
					placeholder="Confirm Password"
					onChange={resetServerError}
				/>
			</div>

			{watch("password") != watch("confirmPassword") && <p role="alert" className="text-red-500">Passwords does not match</p>}

			<div className="text-center pt-1 mb-12 pb-1 mt-4">
				<button
					className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full mb-5"
					type="submit"
					data-mdb-ripple="true"
					data-mdb-ripple-color="light">
					Register account
				</button>


				<p role="alert" className="text-red-500 mb-5">&nbsp; {serverError !== "" && <span>{serverError}</span>}</p>

				<Link to={"/login"} className="text-gray-500 mt-5">Return to login</Link>
			</div>
		</form>)
}