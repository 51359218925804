import React, { Component, useState, useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";

import ReferrerService from "../services/ReferrerService";
import ExperienceService from "../services/ExperienceService";
import GameLinkService from "../services/GameLinkService";

type FormData = {
	payPerClick: number,
	payPerPlay: number
};

function Selector(props: any) {
	const items = props.items

	const change = (selectedItem : any) => {
		props.onChange(selectedItem)
	}


	items.map(function(item : any, i : number) {
		if (!item.label) {
			item.label = item.name
		}

		if (!item.value) {
			item.value = item.id
		}
	});

	return (<div className="mb-6">
		<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="referrerId">{props.title}</label>
		
		<Select options={items} onChange={change} />
	</div>)
}


function CompensationForm(props : any) {
	const [ saveText, setSaveText ] = useState("SAVE")
	const [ createdCompensation, setCreatedCompensation ] = useState(false);
	const { register, setValue, handleSubmit, formState: { errors } } = useForm<FormData>();

	const deleteRefCompensation = () => {
		ExperienceService.deleteReferralCompensation(props.experienceId, props.referrerId).then((response) => {	
			setCreatedCompensation(false);				
			setValue("payPerClick", 0)
			setValue("payPerPlay", 0)
		});
	}

	const setReferralCompensation = (data : FormData) => {
		setSaveText("SAVED!")
		
		ExperienceService.setReferralCompensation(props.experienceId, props.referrerId, data.payPerClick, data.payPerPlay).then((response) => {			
			setTimeout(() => {
				setSaveText("SAVE")
			}, 1000)
		});
	};
	
	useEffect(() => {
		ExperienceService.getReferralCompensation(props.experienceId, props.referrerId).then((response) => {
			setValue("payPerClick", response.data.payPerClick)
			setValue("payPerPlay", response.data.payPerPlay)
			
			setCreatedCompensation(response.data.id != 0);			
		});
	}, [props.experienceId, props.referrerId]);

	const onSubmit = handleSubmit(setReferralCompensation);

	const form = (
		<form onSubmit={onSubmit}>
			<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="referrerId">Pay per Click ($)</label>
			<input
				{...register("payPerClick", { required: true })}
				type="text"
				className="form-control block w-full mb-6 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
				id="exampleFormControlInput1"
				placeholder="0.0$"
			/>

			<label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="referrerId">Pay per Play ($)</label>
			<input
				{...register("payPerPlay", { required: true })}
				type="text"
				className="form-control block w-full mb-6 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
				id="exampleFormControlInput1"
				placeholder="0.0$"
			/>
			
			<button
				className="inline-block px-6 mb-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                type="submit"
                data-mdb-ripple="true"
                data-mdb-ripple-color="light">
                {saveText}
            </button>

			<button
				className="inline-block px-6 py-2.5 border-red-600 border-2 text-red-600 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-100 hover:shadow-lg focus:bg-red-100 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                type="button"
				onClick={() => deleteRefCompensation()}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light">
                DELETE
            </button>
		</form>
	)

	const createButton = (
		<button
			className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
			type="submit"
			data-mdb-ripple="true"
			data-mdb-ripple-color="light"
			onClick={() => setCreatedCompensation(true)}>
			Create compensation
		</button>
	)

	return createdCompensation ? form : createButton
}


interface ICreateTrackingProps {
	experienceId: any,
}

function SetupCompensationForm(props : ICreateTrackingProps) {	
	const [referrers, setReferrers] = useState([]);	
	const [selectedReferrer, setSelectedReferrer] = useState<any>(null);	

	useEffect(() => {  
		ReferrerService.list("all").then((response) => {
			var referrers = response.data

			referrers.unshift({label: "GLOBAL", value: "0"})

			setReferrers(response.data);
		})
	}, []);

	return (
		<div className="w-1/2 rounded-lg bg-white p-6">
			<h4 className="font-bold mb-6">SETUP REFERRAL COMPENSATION</h4>

				<div className="relative w-full mb-3">
					<Selector items={referrers} title="Select referrer" onChange={setSelectedReferrer}/>

					{ selectedReferrer ? <CompensationForm experienceId={props.experienceId} referrerId={selectedReferrer.value} />  : ""}
				</div>
			
			<p className="text-xs">Specific compensation scheme will have priority over global, global will be used if no specific exists.</p>
		</div>
	)
}

export default SetupCompensationForm