import React, { Component, useState, useEffect } from "react";

function Landing() {
    return (
        <div className="">
            <section className="h-full gradient-form md:h-screen">
                <div className="container py-12 px-6 h-full">
                    <div className="lg:w-full flex items-center rounded-lg background-tgblue mb-6">
                        <div className="text-white px-2 py-6 md:px-2 md:py-12 md:mx-6">
                            <h4 className="text-xl font-semibold mb-6">Game Link</h4>
                            <p className="text-sm">
                                Track referrals to Roblox experiences
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Landing