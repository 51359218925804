import axios from "axios";

const API_URL = (process.env.REACT_APP_API_BASE_URL as string);

class AuthService {
	constructor() {
		this.updateAxios();
	}

	userLevels = {
		Default: 0,
		ExperienceOwner: 1,
		SuperAdmin: 2
	}

	updateAxios() {
		var userData = this.getCurrentUser()

		if (userData) {
			axios.defaults.headers.common = {'Authorization': `bearer ${userData.token}`}
		} else {
			axios.defaults.headers.common = {}	
		}
	}

	login(email: string, password: string) {
		return axios
			.post(API_URL + "/api/Users/BearerToken", {
				email: email,
				password: password
			})
			.then((response: any) => {
				if (response.data.token) {
					localStorage.setItem("user", JSON.stringify(response.data));
					this.updateAxios();
				}

				return response.data;
			});
	}

	logout() {
		localStorage.removeItem("user");
	}

	register(email: string, password: string) {
		return axios.post(API_URL + "/api/Users", {
			email: email,
			password: password
		});
	}

	getCurrentUser() {
		var userDataRaw = localStorage.getItem('user');

		if (userDataRaw) {			
			const userData = JSON.parse(userDataRaw)

			if (new Date(userData.expiration) < new Date(Date.now())) {
				this.logout()
				window.location.href = "/";
				return null
			}

			return userData;
		} else {
			return null;
		}
	}

	getUserLevel() {
		const userData = this.getCurrentUser()

		return userData ? userData.userLevel : -1
	}
}

export default new AuthService();
