import React, { Component, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate  } from "react-router-dom";

import ExperienceService from "../services/ExperienceService"

type FormData = {
	name: string;
	slug: string;
	placeId: number;
};


function ExperienceCreate(props: any) {
	const { register, setValue, handleSubmit, formState: { errors } } = useForm<FormData>();
	const [serverError, setServerError] = useState("");
    const navigate = useNavigate();

	const createExperience = (data : FormData) => {
		ExperienceService.createExperience(data).then((response) => {
            navigate("/experiences/" + response.data.id)
        }).catch((error) => {
            if (error.response.status == 400) {
                setServerError(error.response.data)
            }
            else {
                setServerError("Unknown error occured")
            }
        });
	};

	const resetServerError = (e : any) => {
		setServerError("")
		setValue(e.target.name, e.target.value);
	}


	const onSubmit = handleSubmit(createExperience);

    return (
        <div className="">
			<section className="h-full w-1/3 gradient-form md:h-screen">
				<div className="container py-12 px-6 h-full">
					<div className="w-full flex items-center rounded-lg background-tgblue mb-6">
						<div className="text-white px-2 py-6 md:px-2 md:py-12 md:mx-6">
							<h4 className="text-xl font-semibold mb-6">Create Experience</h4>
							<p className="text-sm">
								Check stats and setup compensation plan.
							</p>
						</div>
					</div>
                    
					<div className="w-full rounded-lg bg-white p-6 mb-6">

                        <form onSubmit={onSubmit}>
                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="referrerId">Friendly Name</label>
                            <input
					            {...register("name", { required: true })}
                                type="text"
                                className="form-control block w-full mb-6 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="exampleFormControlInput1"
                                placeholder="Friendly Name"
                                onChange={resetServerError}
                            />

                            {errors.name?.type === 'required' && <p role="alert" className="text-red-500 mb-6">Name is required</p>}

                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="referrerId">Slug (used in the link)</label>
                            <input
                                {...register("slug", { required: true })}
                                type="text"
                                className="form-control block w-full mb-6 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="exampleFormControlInput1"
                                placeholder="mychannel"
                                onChange={resetServerError}
                            />

                            {errors.name?.type === 'required' && <p role="alert" className="text-red-500 mb-6">Name is required</p>}

                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="placeId">PlaceId</label>
                            <input
                                {...register("placeId", { required: true })}
                                type="number"
                                className="form-control block w-full mb-6 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                id="exampleFormControlInput1"
                                placeholder="1234"
                                onChange={resetServerError}
                            />

                            {errors.placeId?.type === 'required' && <p role="alert" className="text-red-500 mb-6">PlaceId is required</p>}

                            <button
                                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out w-full"
                                type="submit"
                                data-mdb-ripple="true"
                                data-mdb-ripple-color="light">
                                Create
                            </button>

                            
				            <p role="alert" className="text-red-500 mb-5 mt-5">&nbsp; {serverError !== "" && <span>{serverError}</span>}</p>
                        </form>
					</div>
				</div>
			</section>
		</div>
    )
}

export default ExperienceCreate