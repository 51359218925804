import axios from "axios";

const API_URL = (process.env.REACT_APP_API_BASE_URL as string);

class ExperienceService {
    
    list(filter : string) {
        if (!filter) {
            filter = "all"
        }
        
        return axios.get(API_URL + "/api/Experiences?filter=" + filter)
    }

    getHitStats(id : string) {
        return axios.get(API_URL + "/api/Experiences/" + id + "/HitStats")
    }

    setReferralCompensation(experienceId : string, referrerId : string, payPerClick : number, payPerPlay : number) {
        return axios.post(API_URL + "/api/Experiences/" + experienceId + "/Compensation/" + referrerId, {
            payPerClick: payPerClick,
            payPerPlay: payPerPlay,
        })
    }
    
    createExperience(data : any) {
        return axios.post(API_URL + "/api/Experiences", data)
    }

    getReferralCompensation(experienceId : string, referrerId : string) {
        return axios.get(API_URL + "/api/Experiences/" + experienceId + "/Compensation/" + referrerId)
    }

    deleteReferralCompensation(experienceId : string, referrerId : string) {
        return axios.delete(API_URL + "/api/Experiences/" + experienceId + "/Compensation/" + referrerId)
    }

    
}

export default new ExperienceService();