import React, { Component } from "react";
import AuthService from "../services/AuthService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools, faRightFromBracket, faGamepad, faBolt } from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";


function NavBar() {
	const navigate = useNavigate()

	const logout = () => {
		AuthService.logout()
		window.location.href = "/login";
	}

	
	const configValue : string = (process.env.REACT_APP_CONFIG as string);
	
	return (
		<nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
			<Link to="/" className="md:block text-left md:pb-2 text-blue-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0">Game Link</Link>
			<hr className="my-4 md:min-w-full"></hr>

			{AuthService.getUserLevel() >= AuthService.userLevels.ExperienceOwner ?
				<Link to="/experiences" className="text-xs uppercase py-3 font-bold block text-slate-700 hover:text-slate-500"><FontAwesomeIcon icon={faGamepad} className="mr-2 text-sm"></FontAwesomeIcon> Experiences</Link> :
				""
			}


			<Link to="/referrers" className="text-xs uppercase py-3 font-bold block text-slate-700 hover:text-slate-500"><FontAwesomeIcon icon={faBolt} className="mr-2 text-sm"></FontAwesomeIcon> Referrer Profiles</Link>
			<Link to={"/users/" + AuthService.getCurrentUser()?.userId} className="text-xs uppercase py-3 font-bold block text-slate-700 hover:text-slate-500"><FontAwesomeIcon icon={faTools} className="mr-2 text-sm"></FontAwesomeIcon> Settings</Link>


			<hr className="my-4 md:min-w-full"></hr>
			<button onClick={logout} className="text-xs uppercase py-3 font-bold block text-slate-700 hover:text-slate-500"><FontAwesomeIcon icon={faRightFromBracket} className="mr-2 text-sm"></FontAwesomeIcon> Logout</button>

			<p className="text-xs text-center mt-10">{configValue}</p>
		</nav>
	);
}

export default NavBar;