import React, { Component, useState, useEffect } from "react";
import { Link, useParams, } from "react-router-dom";

import AuthService from "../services/AuthService";
import ReferrerService from "../services/ReferrerService";
import CreateTrackingForm from "../components/CreateTrackingForm";
import DataBox from "../components/DataBox";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools, faRightFromBracket, faGamepad, faBolt } from "@fortawesome/free-solid-svg-icons";
import { click } from "@testing-library/user-event/dist/click";

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

const logo = require("../img/logo_thegang_square_black.png");

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			position: 'bottom' as const,
		},
		title: {
			display: false,
			text: 'Chart.js Line Chart',
		},
	},
	scales: {
		x: {
			reverse: true,
		}
	},
};

function Referrer(props: any) {
	var { referrerId } = useParams()

	const [isLoadingHitStats, setLoadingHitStats] = useState(true);
	const [hitStats, setHitStats] = useState({});
	const [balance, setBalance] = useState(0);

	useEffect(() => {
		ReferrerService.getHitStats(String(referrerId)).then((response) => {
			setHitStats(response.data);
			setLoadingHitStats(false);
		});

		ReferrerService.getBalance(String(referrerId)).then((response) => {
			setBalance(response.data.balance);
		})
	}, []);

	let clicks = 0
	let plays = 0
	let pay = 0
	let labels: any[] = []
	let clickArray: any[] = []
	let playArray: any[] = []
	let payArray: any[] = []

	Object.entries(hitStats).forEach(([key, value]) => {
		let stat: any = value

		clicks += stat.clicks
		plays += stat.plays
		pay += stat.pay

		labels.push(new Date(key).toLocaleDateString())
		clickArray.push(stat.clicks)
		playArray.push(stat.plays)
		payArray.push(stat.pay)
	});

	const data = {
		labels: labels,
		datasets: [
			{
				label: 'Clicks',
				data: clickArray,
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
			{
				label: 'Plays',
				data: playArray,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: 'rgba(53, 162, 235, 0.5)',
			},
			{
				label: 'Earnings',
				data: payArray,
				borderColor: 'rgb(53, 235, 162)',
				backgroundColor: 'rgba(53, 235, 162, 0.5)',
			},
		],
	};

	return (
		<div className="">
			<section className="h-full gradient-form md:h-screen">
				<div className="container py-12 px-6 h-full">
					<div className="lg:w-full flex items-center rounded-lg background-tgblue mb-6">
						<div className="text-white px-2 py-6 md:px-2 md:py-12 md:mx-6">
							<h4 className="text-xl font-semibold mb-6">Referrer Profile</h4>
							<p className="text-sm">
								Check stats and generate links to experiences.
							</p>
						</div>
					</div>

					<div className="grid grid-cols-4 mb-6 gap-x-8">
						<DataBox title="Balance" amount={"$" + balance.toFixed(2)} bgClass="bg-red-500" icon={faBolt} />
						<DataBox title="Clicks (14d)" amount={clicks} bgClass="bg-blue-500" icon={faBolt} />
						<DataBox title="Plays (14d)" amount={plays} bgClass="bg-green-500" icon={faBolt} />
						<DataBox title="Earnings (14d)" amount={"$" + pay.toFixed(2)} bgClass="bg-yellow-500" icon={faBolt} />
					</div>

					<div className="lg:w-full rounded-lg bg-white p-6 mb-6 h-80">
						{isLoadingHitStats ? "Loading" : <Line options={options} data={data} />}
					</div>

					<CreateTrackingForm referrerId={referrerId} />
				</div>
			</section>
		</div>
	);
}


export default Referrer;