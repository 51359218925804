import React, { Component } from "react";
import AuthService from "../services/AuthService";
import LoginForm from "../components/LoginForm"

const logo = require("../img/logo_thegang_square_black.png");

class Login extends Component {
	constructor(props : any) {
		super(props);
	}

	render() {
		return (
			<div className="">
				<section className="h-full gradient-form md:h-screen">
					<div className="container py-12 px-6 h-full mx-auto">
						<div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
							<div className="xl:w-10/12">
								<div className="block bg-white shadow-lg rounded-lg">
									<div className="lg:flex lg:flex-wrap g-0">
										<div className="lg:w-6/12 px-4 md:px-0">
											<div className="md:p-12 md:mx-6">
												<div className="text-center">
													<img
														className="mx-auto w-48 pb-5"
														src={String(logo)}
														alt="logo"
													/>
													<h4 className="text-xl font-semibold mt-1 mb-12 pb-1">Game Links</h4>
												</div>
												
												<LoginForm />
											</div>
										</div>
										<div className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none background-tgblue">
											<div className="text-white px-4 py-6 md:p-12 md:mx-6">
												<h4 className="text-xl font-semibold mb-6">The one place to manage your influencer referrals</h4>
												<p className="text-sm">
													Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
													tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
													quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
													consequat.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}


export default Login;