import { wait } from "@testing-library/user-event/dist/utils";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthService from "../services/AuthService";
import ExperienceService from "../services/ExperienceService";

const logo = require("../img/logo_thegang_square_black.png");

function ExperienceRow(props: any) {

	return (
		<Link to={"/experiences/" + props.id} className="table-row hover:bg-sky-100">
			<div className="table-cell px-2 py-3 font-semibold">{props.name}</div>
			<div className="table-cell px-2 py-3">{props.slug}</div>
		</Link>
	)
}

interface IAppProps {
}

interface IAppState {
	isLoading: boolean,
	experiences: any,
}

class Experiences extends Component<IAppProps, IAppState> {
	constructor(props: any) {
		super(props);

		this.state = {
			isLoading: true,
			experiences: []
		}
	}

	componentDidMount() {
		ExperienceService.list("mine").then((response) => {
			this.setState({
				isLoading: false,
				experiences: response.data
			});
		})
	}

	render() {
		return (
			<div className="">
				<section className="h-full gradient-form md:h-screen">
					<div className="container py-12 px-6 h-full">
						<div className="lg:w-full flex items-center rounded-lg background-tgblue mb-6">
							<div className="text-white px-2 py-6 md:px-2 md:py-12 md:mx-6">
								<h4 className="text-xl font-semibold mb-6">Experiences</h4>
								<p className="text-sm">
									Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
									tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
									quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
									consequat.
								</p>
							</div>
						</div>

						<div className="lg:w-full rounded-lg bg-white p-6">
							{this.state.isLoading ?
								<p className="text-center py-5 w-full">Loading...</p> : (
									<div className="table table-fixed w-full text-left mb-6 text-sm">
										<div className="table-header-group">
											<div className="table-row font-bold text-slate-500">
												<div className="table-cell p-2">EXPERIENCE</div>
												<div className="table-cell p-2">SLUG</div>
											</div>
										</div>

										<div className="table-row-group">
											{this.state.experiences.map(function(referrer : any, i : number){
												return <ExperienceRow key={referrer.id} name={referrer.name} id={referrer.id} slug={referrer.slug} clicks={"N/A"} plays={"N/A"} />;
											})}
										</div>
									</div>
								)}

							<div className="grid  place-content-end">
								<Link
									to="/experiences/new"
									type="button"
									className="place-self-end px-6 py-2 border-2 border-blue-600 text-blue-600 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
									data-mdb-ripple="true"
									data-mdb-ripple-color="light">
									Create new experience
								</Link>
							</div>
						</div>

					</div>
				</section>
			</div>
		);
	}
}


export default Experiences;