import axios from "axios";

const API_URL = (process.env.REACT_APP_API_BASE_URL as string);

class UserService {    
    listApiKeys(userId : string) {
        return axios.get(API_URL + "/api/Users/" + userId + "/ApiKeys")
    } 
    
    createApiKey(userId : string) {
        return axios.post(API_URL + "/api/Users/" + userId + "/ApiKeys")
    }  

    deleteApiKey(userId : string, keyId : number) {
        return axios.delete(API_URL + "/api/Users/" + userId + "/ApiKeys/" + keyId)
    }    
}

export default new UserService();